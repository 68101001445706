import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

import './src/styles/global.css';

const theme = createTheme({
  palette: {
    white: {
      main: '#ffffff',
    },
  },
  typography: {
    fontFamily: [`"Roboto Condensed"`, `sans-serif`].join(','),
  },
});

theme.typography.body1 = {
  fontFamily: [`"Roboto Condensed"`, `sans-serif`].join(','),
  fontWeight: 400,
  lineHeight: 1.5,
  fontSize: '0.875rem',
  [theme.breakpoints.up('lg')]: {
    fontSize: '1rem',
  },
};

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {element}
    </ThemeProvider>
  );
};
